var app = angular.module("TimFlowApp", []);


app.config(function ($interpolateProvider) {
    $interpolateProvider.startSymbol('[[');
    $interpolateProvider.endSymbol(']]');
});

app.run(function($rootScope) {
    $rootScope.user = {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
        password_confirmation: "",
        terms: 0
    };

    $rootScope.contact = {
        subject: "",
        email: "",
        phone: "",
        message: "",
        terms: 0
    };

    $rootScope.document = {
        subject: "",
        email: "",
        message: "",
        terms: 0
    };
});

app.directive("compareTo", function() {
    return {
        restrict: 'A',
        require: "ngModel",
        scope: {
            otherModelValue: "=compareTo"
        },
        link: function(scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});
